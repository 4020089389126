/* eslint-disable react/prop-types */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase, Collapse, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { PropsWithChildren, useState } from 'react';
import ThemeService from '../../services/theme/ThemeService';
import useCommunicationStyles from './CommunicationStyles';

interface PropTypes{
  persistent?: boolean
  message?: string,
  className: string,
}

const Communication: React.FC<PropsWithChildren<PropTypes>> = (props) => {
  const { persistent, message, className, children } = props;
  const styles = useCommunicationStyles(ThemeService.currentUserTheme());
  const [display, setDisplay] = useState(true);

  const handleClose = () => {
    setDisplay(false);
  };

  return (
    <Collapse in={display}>
      <div className={clsx(styles.communicationContainer, { [className]: true })}>
        <div>
          {
            message ? (
              <Typography variant="body2" component="p">{message}</Typography>
            ) : (
              children
            )
          }
        </div>

        { !persistent
          && (
          <ButtonBase onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </ButtonBase>
          )}
      </div>
    </Collapse>
  );
};

export default Communication;
