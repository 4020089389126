import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const useCommunicationStyles = makeStyles((theme: Theme) => createStyles({
  communicationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    backgroundColor: colors.secondary,
  },
  communicationContainerHide: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    communicationContainer: {
      padding: theme.spacing(2),
    },
  },
}));

export default useCommunicationStyles;
