import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const useCompanyStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    padding: theme.spacing(3),
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.primary,
    fontWeight: 700,
    borderBottomColor: colors.secondary,
    borderBottomWidth: theme.spacing(1) / 2,
    borderBottomStyle: 'solid',
    marginBottom: theme.spacing(5),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  image: {
    width: '90%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    textContainer: {
      paddingRight: theme.spacing(5),
    },
  },
}));

export default useCompanyStyles;
