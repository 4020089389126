import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ThemeService from '../../services/theme/ThemeService';
import useCompanyStyles from './CompanyStyles';

const Company = () => {
  const [t] = useTranslation();
  const styles = useCompanyStyles(ThemeService.currentUserTheme());

  return (
    <div id="company">
      <Grid container className={styles.content}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography variant="h5" component="h3" className={styles.title}>
                {t('company.title')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} className={styles.textContainer}>
          <Typography variant="body1" component="p" align="justify" className={styles.text}>
            {t('company.text1')}
          </Typography>
          <Typography variant="body1" component="p" align="justify" className={styles.text}>
            {t('company.text2')}
          </Typography>
          <Typography variant="body1" component="p" align="justify" className={styles.text}>
            {t('company.text3')}
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <img src="assets/company.webp" alt="company" className={styles.image} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Company;
