import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../styles/colors';

const useCovidAlerttyles = makeStyles((theme: Theme) => createStyles({
  covidAlert: {
    backgroundColor: colors.red,
  },
  covidContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}));

export default useCovidAlerttyles;
