import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ThemeService from '../../services/theme/ThemeService';
import useHomeStyles from './HomeStyles';

const Home = () => {
  const [t] = useTranslation();
  const styles = useHomeStyles(ThemeService.currentUserTheme());

  return (
    <div className={styles.background} id="home">
      <Grid container justify="flex-end" className={styles.content}>
        <Grid item md={6} xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <img alt="bika logo" src="assets/logo-bika-full-white.webp" className={styles.logo} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" component="h2" className={styles.slogan}>
                {t('home.slogan')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" className={styles.button} href="#contact">
                {t('home.get_in_contact')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
