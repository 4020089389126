import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const usePartnersStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.secondary,
    fontWeight: 700,
    borderBottomColor: colors.primary,
    borderBottomWidth: theme.spacing(1) / 2,
    borderBottomStyle: 'solid',
    marginBottom: theme.spacing(5),
  },
  gridPartner: {
    width: '70%',
    margin: 'auto',
  },
  gridPartnerImage: {
    width: '150px',
    margin: 'auto',
    filter: 'grayscale(100%)',
    '&:hover': {
      filter: 'grayscale(0%)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridProduct: {
      width: '100%',
    },
    gridProductItem: {
      height: '10vh',
    },
  },
}));

export default usePartnersStyles;
