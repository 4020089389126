import i18next from 'i18next';
import { FormFieldState, FormFieldError } from 'form-field-state';

const validateEmptyField = (field: FormFieldState<string>): FormFieldError => {
  let hasErrors = false;
  let message = null;

  if (!field.value || field.value === '') {
    hasErrors = true;
    message = i18next.t('validations.empty_field');
  }

  return {
    hasErrors,
    message,
  };
};

const validateEmail = (field: FormFieldState<string>) : FormFieldError => {
  let hasErrors = false;
  let message = null;
  const EMAIL_REGEX = /\S+@\S+\.\S+/;

  if ((!field.value || !EMAIL_REGEX.test(field.value)) && field.value !== ' ') {
    hasErrors = true;
    message = i18next.t('validations.invalid_email');
  }

  return {
    hasErrors, message,
  };
};

export default { validateEmptyField, validateEmail };
