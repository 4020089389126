import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const useContactStyles = makeStyles((theme: Theme) => createStyles({
  background: {
    backgroundColor: colors.primary,
  },
  contentContainer: {
    padding: theme.spacing(3),
    width: '70%',
    margin: 'auto',
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.white,
    fontWeight: 700,
    borderBottomColor: colors.secondary,
    borderBottomWidth: theme.spacing(1) / 2,
    borderBottomStyle: 'solid',
    marginBottom: theme.spacing(5),
  },
  contactForm: {
    marginRight: theme.spacing(3),
    position: 'relative',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  submitLoading: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  submitFailed: {
    width: '100%',
  },
  contactInfoContainer: {
    marginLeft: theme.spacing(3),
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    '& span': {
      color: colors.white,
      verticalAlign: 'super',
    },
  },
  contactButtonIcon: {
    marginRight: theme.spacing(1),
  },
  contactInfoIcon: {
    marginRight: theme.spacing(1),
    color: colors.white,
    minWidth: '32px',
  },
  contactInfoText: {
    color: colors.white,
    verticalAlign: 'super',
  },
  alertContainer: {
    backgroundColor: '#FF9800',
    display: 'flex',
    padding: '6px 16px',
    borderRadius: '4px',
    alignItems: 'center',
    '& svg': {
      marginRight: '10px',
    },
    '& p': {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: colors.black,
      paddingBottom: '5px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    contentContainer: {
      width: '100%',
    },
    gridProductItem: {
      height: '10vh',
    },
    contactInfoContainer: {
      marginLeft: 0,
    },
    contactForm: {
      marginRight: 0,
    },
  },
}));

export default useContactStyles;
