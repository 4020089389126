import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const useProductsStyles = makeStyles((theme: Theme) => createStyles({
  background: {
    backgroundColor: colors.secondary,
  },
  content: {
    padding: theme.spacing(3),
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.white,
    fontWeight: 700,
    borderBottomColor: colors.primary,
    borderBottomWidth: theme.spacing(1) / 2,
    borderBottomStyle: 'solid',
    marginBottom: theme.spacing(5),
  },
  gridProduct: {
    width: '70%',
    margin: 'auto',
  },
  gridProductItem: {
    height: '30vh',
    position: 'relative',
    '&:hover $gridProductImage': {
      filter: 'brightness(70%)',
    },
    '&:hover $gridProductName': {
      borderStyle: 'solid',
      padding: '5px',
    },
  },
  gridItemContainer: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    width: '100%',
  },
  gridProductImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'brightness(40%)',
  },
  gridProductNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridProductName: {
    color: colors.white,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  image: {
    width: '90%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    textContainer: {
      paddingRight: theme.spacing(5),
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridProduct: {
      width: '100%',
    },
    gridProductItem: {
      height: '10vh',
    },
  },
}));

export default useProductsStyles;
