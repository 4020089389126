const colors = {
  lightGray: '#EBEEF0',
  gray: '#CCC',
  darkGray: '#333',
  primary: '#0C60A6',
  primaryDark: '#2196F3',
  primaryLight: '#3DA8FF',
  secondary: '#F2A122',
  secondaryDark: '#A66500',
  white: '#FFFFFF',
  black: '#000000',
  red: '#F44336',
};

export default colors;
