import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useHeaderStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
}));

export default useHeaderStyles;
