import { Button, CircularProgress, Grid, TextField, ThemeProvider, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faMapMarkedAlt, faMobileAlt, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useFormFieldState } from 'form-field-state';
import ThemeService from '../../services/theme/ThemeService';
import FieldValidators from '../../validators/FieldValidators';
import ContactService from '../../services/contact/ContactService';
import usePartnersStyles from './ContactStyles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ContactForm from '../../entities/ContactForm';

const Contact = () => {
  const [t] = useTranslation();
  const styles = usePartnersStyles(ThemeService.currentUserTheme());
  const [nameField, setNameField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const [phoneField, setPhoneField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const [emailField, setEmailField] = useFormFieldState<string>('', FieldValidators.validateEmail);
  const [messageField, setMessageField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const [submitting, setSubmitting] = useState(false);
  const [showErrorSubmit, setShowErrorSubmit] = useState(false);

  const cleanFields = () => {
    setNameField(' ');
    setPhoneField(' ');
    setEmailField(' ');
    setMessageField(' ');
  };

  const submitContactForm = () => {
    const fields = [nameField, phoneField, emailField, messageField];
    let hasErrors = false;
    fields.forEach((field) => {
      if (field.validate().hasErrors) {
        hasErrors = true;
      }
    });

    if (!hasErrors) {
      setSubmitting(true);
      const contactForm = new ContactForm(nameField.value?.trim()!, phoneField.value?.trim()!,
        emailField.value?.trim()!, messageField.value?.trim()!);

      ContactService.send(contactForm)
        .then(() => {
          cleanFields();
          setSubmitting(false);
        }).catch(() => {
          setSubmitting(false);
          setShowErrorSubmit(true);
        });
    }
  };

  return (
    <div id="contact" className={styles.background}>
      <Grid container className={styles.contentContainer}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography variant="h5" component="h3" className={styles.title}>
                {t('contact.title')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container className={styles.contactForm}>
            { submitting
              && (
              <div className={styles.submitLoading}>
                <CircularProgress color="secondary" size={60} />
              </div>
              )}
            <ThemeProvider theme={ThemeService.contactFormTheme()}>
              <TextField
                fullWidth
                label={t('contact.name')}
                variant="filled"
                required
                onBlur={(e) => setNameField(e.target.value)}
                onChange={(e) => setNameField(e.target.value)}
                error={nameField.hasErrors}
                helperText={nameField.errorMessage}
                value={nameField.value}
              />
              <TextField
                fullWidth
                label={t('contact.phone')}
                variant="filled"
                required
                onBlur={(e) => setPhoneField(e.target.value)}
                onChange={(e) => setPhoneField(e.target.value)}
                error={phoneField.hasErrors}
                helperText={phoneField.errorMessage}
                value={phoneField.value}
              />
              <TextField
                fullWidth
                label={t('contact.email')}
                variant="filled"
                required
                onBlur={(e) => setEmailField(e.target.value)}
                onChange={(e) => setEmailField(e.target.value)}
                error={emailField.hasErrors}
                helperText={emailField.errorMessage}
                value={emailField.value}
              />
              <TextField
                id="filled-textarea"
                label={t('contact.message')}
                multiline
                fullWidth
                variant="filled"
                rows={3}
                required
                onBlur={(e) => setMessageField(e.target.value)}
                onChange={(e) => setMessageField(e.target.value)}
                error={messageField.hasErrors}
                helperText={messageField.errorMessage}
                value={messageField.value}
              />
            </ThemeProvider>
            <Button variant="contained" color="secondary" fullWidth onClick={() => submitContactForm()}>
              <FontAwesomeIcon icon={faPaperPlane} className={styles.contactButtonIcon} size="lg" />
              {t('contact.send')}
            </Button>
            { showErrorSubmit
              && (
              <Alert severity="error" variant="filled" className={styles.submitFailed}>
                {t('contact.send_fail')}
              </Alert>
              )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container className={styles.contactInfoContainer}>
            <Grid item xs={12}>
              <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" className={styles.contactInfoIcon} />
              <Typography variant="body1" component="span">{t('contact.bika_address')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FontAwesomeIcon icon={faPhone} size="2x" className={styles.contactInfoIcon} />
              <Typography variant="body1" component="span">{t('contact.bika_phone')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FontAwesomeIcon icon={faMobileAlt} size="2x" className={styles.contactInfoIcon} />
              <Typography variant="body1" component="span">{t('contact.bika_cellphone')}</Typography>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.alertContainer}>
                <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                <div>
                  <Typography variant="body1" component="p">{t('alerts.covid_alert')}</Typography>
                  <Typography variant="body1" component="p">{t('alerts.covid_alert_2')}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
