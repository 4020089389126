import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, ThemeProvider, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ThemeService from '../../services/theme/ThemeService';
import useHeaderStyles from './HeaderStyles';

interface PropTypes{
  onToggleMenu: () => void
}

const Header = (props: PropTypes) => {
  const { onToggleMenu } = props;
  const [t] = useTranslation();
  const styles = useHeaderStyles(ThemeService.currentUserTheme());

  return (
    <ThemeProvider theme={ThemeService.currentHeaderTheme}>
      <AppBar
        position="fixed"
        className={styles.appBar}
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <Toolbar>
          <FontAwesomeIcon
            className={styles.menuButton}
            icon={faBars}
            onClick={onToggleMenu}
            size="lg"
          />
          <Typography variant="h5" noWrap>
            {t('app_title')}
          </Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
