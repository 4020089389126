import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Communication from '../../../components/Communication';
import ThemeService from '../../../services/theme/ThemeService';
import useGDPRAlertStyles from './GDPRAlertStyles';

const GDPRAlert = () => {
  const [t] = useTranslation();
  const styles = useGDPRAlertStyles(ThemeService.currentUserTheme());

  return (
    <Communication className={styles.gdprAlert} persistent>
      <div className={styles.gdprContainer}>
        <FontAwesomeIcon icon={faInfoCircle} size="lg" />
        <div>
          <Typography variant="body2" component="p">{t('alerts.gdpr_alert')}</Typography>
        </div>
      </div>
    </Communication>
  );
};

export default GDPRAlert;
