import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ThemeService from '../../services/theme/ThemeService';
import useProductsStyles from './ProductsStyles';

const Products = () => {
  const [t] = useTranslation();
  const styles = useProductsStyles(ThemeService.currentUserTheme());

  const products = [
    { name: t('products.acidents'), size: 5, file: 'acidents.webp' },
    { name: t('products.auto'), size: 5, file: 'auto.webp' },
    { name: t('products.building'), size: 2, file: 'building.webp' },
    { name: t('products.company'), size: 5, file: 'company.webp' },
    { name: t('products.arts'), size: 2, file: 'arts.webp' },
    { name: t('products.pension'), size: 5, file: 'pension.webp' },
    { name: t('products.house'), size: 4, file: 'house.webp' },
    { name: t('products.travel'), size: 4, file: 'travel.webp' },
    { name: t('products.life'), size: 4, file: 'life.webp' },
  ];

  return (
    <div id="products" className={styles.background}>
      <Grid container className={styles.content}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography variant="h5" component="h3" className={styles.title}>
                {t('products.title')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container className={styles.gridProduct}>
            { products.map((product) => (
              <Grid item xs={12} md={product.size as any} className={styles.gridProductItem} key={product.name}>
                <div className={clsx(styles.gridProductImage, styles.gridItemContainer)} style={{ backgroundImage: `url("assets/products/${product.file}")` }} />
                <div className={clsx(styles.gridItemContainer, styles.gridProductNameContainer)}>
                  <Typography variant="h6" component="h4" className={styles.gridProductName}>{product.name}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Products;
