import ContactForm from '../../entities/ContactForm';

const API_URL = 'https://ds31sx30w8.execute-api.sa-east-1.amazonaws.com/prod/bika_website_contact';

const convertObjectToBody = (contact: ContactForm) => ({
  name: contact.name,
  phone: contact.phone,
  email: contact.email,
  message: contact.message,
});

const send = (contact: ContactForm): Promise<Response> => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Request-Method': 'POST,OPTIONS',
      'Access-Control-Request-Headers': 'Content-Type,x-requested-with,Access-Control-Allow-Origin,Access-Control-Allow-Headers,Access-Control-Allow-Methods',
    },
    body: JSON.stringify(convertObjectToBody(contact)),
  };
  return fetch(API_URL, requestOptions);
};

export default { send };
