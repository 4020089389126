import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import ThemeService from '../../services/theme/ThemeService';
import usePartnersStyles from './PartnersStyles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Partners = () => {
  const [t] = useTranslation();
  const styles = usePartnersStyles(ThemeService.currentUserTheme());
  const theme = useTheme();
  const isXsSize = useMediaQuery(theme.breakpoints.down('sm'));

  const partners = [
    'allianz.webp', 'azul.webp', 'bradesco.webp', 'chubb.webp', 'hdi.webp',
    'icatu.webp', 'investprev.webp', 'liberty.webp', 'mapfre.webp',
    'porto_seguro.webp', 'sompo.webp', 'sulamerica.webp', 'tokiomarine.webp',
  ];

  const sliderSettings = {
    dots: !isXsSize,
    infinite: true,
    speed: 1000,
    slidesToShow: isXsSize ? 1 : 3,
    slidesToScroll: isXsSize ? 1 : 3,
    autoplay: true,
    autoplaySpeed: isXsSize ? 2000 : 4000,
    pauseOnHover: true,
  };

  return (
    <div id="partners" className={styles.background}>
      <Grid container className={styles.content}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography variant="h5" component="h3" className={styles.title}>
                {t('partners.title')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Slider
            {...sliderSettings}
            className={styles.gridPartner}
          >
            {partners.map((partner) => (
              <div key={partner}>
                <img src={`assets/partners/${partner}`} alt={partner} className={styles.gridPartnerImage} />
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
};

export default Partners;
