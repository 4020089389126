import React, { Suspense, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import ThemeService from './services/theme/ThemeService';
import './App.css';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import Home from './sections/Home/Home';
import Company from './sections/Company/Company';
import Products from './sections/Products/Products';
import Partners from './sections/Partners/Partners';
import Contact from './sections/Contact/Contact';
import Footer from './components/Footer';
import useAppStyles from './AppStyles';
import CovidAlert from './sections/Alerts/CovidAlert';
import LoadingPage from './components/LoadingPage';
import GDPRAlert from './sections/Alerts/GDPRAlert';

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const styles = useAppStyles(ThemeService.currentUserTheme);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <ThemeProvider theme={ThemeService.currentUserTheme()}>
      <Suspense fallback={<LoadingPage />}>
        <CssBaseline />
        <Header onToggleMenu={handleToggleMenu} />
        <SideMenu open={menuOpen} />
        <div
          className={clsx(styles.content, { [styles.contentShift]: menuOpen })}
        >
          <Toolbar />
          <CovidAlert />
          <Home />
          <Company />
          <Products />
          <Partners />
          <Contact />
          <GDPRAlert />
          <Footer />
        </div>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
