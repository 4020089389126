import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const useHomeStyles = makeStyles((theme: Theme) => createStyles({
  background: {
    position: 'relative',
    '&:before': {
      filter: 'brightness(60%)',
      background: 'url("assets/home.webp") center center fixed',
      backgroundPosition: '50%',
      backgroundSize: 'cover',
      content: 'no-open-quote',
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      zIndex: -1,
    },
  },
  content: {
    padding: theme.spacing(3),
  },
  slogan: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    color: colors.white,
  },
  logo: {
    width: '150px',
    marginTop: theme.spacing(10),
  },
  button: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.down('md')]: {
    slogan: {
      fontSize: '2rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    slogan: {
      fontSize: '1.5rem',
    },
  },
}));

export default useHomeStyles;
