import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Communication from '../../../components/Communication';
import ThemeService from '../../../services/theme/ThemeService';
import useCovidAlerttyles from './CovidAlertStyles';

const CovidAlert = () => {
  const [t] = useTranslation();
  const styles = useCovidAlerttyles(ThemeService.currentUserTheme());

  return (
    <Communication className={styles.covidAlert}>
      <div className={styles.covidContainer}>
        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
        <div>
          <Typography variant="body2" component="p">{t('alerts.covid_alert')}</Typography>
          <Typography variant="body2" component="p">{t('alerts.covid_alert_2')}</Typography>
        </div>
      </div>
    </Communication>
  );
};

export default CovidAlert;
