import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../styles/colors';

const useGDPRAlertStyles = makeStyles((theme: Theme) => createStyles({
  gdprAlert: {
    backgroundColor: colors.secondary,
  },
  gdprContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}));

export default useGDPRAlertStyles;
