import { faBriefcase, faEnvelope, faFileContract, faHandsHelping, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ThemeService from '../../services/theme/ThemeService';
import useSideMenuStyles from './SideMenuStyles';

interface PropTypes{
  open?: boolean
}

const SideMenu = (props: PropTypes) => {
  const { open } = props;
  const [t] = useTranslation();
  const styles = useSideMenuStyles(ThemeService.currentUserTheme());

  return (
    <Drawer
      className={styles.drawer}
      variant="persistent"
      classes={{
        paper: styles.drawerPaper,
      }}
      open={open || false}
    >
      <Toolbar />
      <div className={styles.drawerContainer}>
        <List>
          <a href="#home">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon size="lg" icon={faHome} className={styles.menuListIcon} />
              </ListItemIcon>
              <ListItemText primary={t('menu.home')} />
            </ListItem>
          </a>
          <a href="#company">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon size="lg" icon={faBriefcase} className={styles.menuListIcon} />
              </ListItemIcon>
              <ListItemText primary={t('menu.company')} />
            </ListItem>
          </a>
          <a href="#products">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon size="lg" icon={faFileContract} className={styles.menuListIcon} />
              </ListItemIcon>
              <ListItemText primary={t('menu.products')} />
            </ListItem>
          </a>
          <a href="#partners">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon size="lg" icon={faHandsHelping} className={styles.menuListIcon} />
              </ListItemIcon>
              <ListItemText primary={t('menu.partners')} />
            </ListItem>
          </a>
          <a href="#contact">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon size="lg" icon={faEnvelope} className={styles.menuListIcon} />
              </ListItemIcon>
              <ListItemText primary={t('menu.contact')} />
            </ListItem>
          </a>
        </List>
      </div>
    </Drawer>
  );
};

export default SideMenu;
